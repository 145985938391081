import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RegionPage from "./pages/RegionPage";

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.css';
import './styles/head.css';
import MainPage from "./pages/MainPage";

const App = () => {
    return (
        <Router>
            <Routes>
                {/* Главная страница */}
                <Route path="/" element={<MainPage/>} key={1} />

                {/* Динамическая страница региона */}
                <Route path="/:locationSlug" element={<RegionPage />} key={2}/>
            </Routes>
        </Router>
    );
};

export default App;

