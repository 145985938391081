import HeaderComponent from "../components/HeaderComponent";

const Layout = ({ children }) => {
    return (<div >
            <HeaderComponent />
        <div className="ms-lg-5 me-lg-5 ps-lg-5 pe-lg-5 ms-3 me-3">
            {children}
        </div>
    </div>);
};

export default Layout;