import React from "react";
import { useParams } from "react-router-dom";
import urls from "./../urls.json"; // Импортируйте JSON с регионами
import regions from "./../russia-regions.json"; // Импортируйте JSON с регионами
import { Helmet } from "react-helmet";
import { Route, Link } from "react-router-dom";
import Layout from "../layout/LayoutBasic";

const RegionPage = () => {
    const { locationSlug } = useParams(); // Получаем slug из URL

    // Ищем регион по slug
    const region = urls.find((r) => r.url === locationSlug);

    if (!region) {
        return <h1>Регион не найден</h1>;
    }

    const handleRedirect = () => {
        window.open("https://такси-водитель.рф/лоба-такси", "_blank", "noopener,noreferrer");

    };

    return (
        <>
            <Helmet>
                <title>Электронные путевые листы - {region.name}</title>
                <meta
                    name="description"
                    content={`Электронные путевые Беслпатно ${region.name}.`}
                />
            </Helmet>
            <Layout>

                <div className="row mb-4">
                    <div className="col-md-8 col-sm-12 card">
                        <div className="row small p-2">
                            <div className="col border text-center m-1"> Регион</div>
                            <div className="col border text-center m-1"> Город</div>
                            <div className="col border text-center m-1"> Тип транспорта </div>
                            <div className="col border text-center m-1"> Тип путевого</div>
                            <div className="col border text-center m-1"> Лицензия такси</div>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-9 col-12 mb-5 mb-md-0">
                        <div className="row align-items-center">
                            <div className="col-12 card rounded-0 shadow-lg text-center mb-5 mb-md-4">
                                <div className="row">
                                    <div className="d-none col-md-2 p-0 d-md-flex flex-column">
                                        <img src="images/lady.jpg" className="img-fluid mt-auto" alt=""/>
                                    </div>
                                    <div className="col-12 col-md-8">
                                        <div className="row ">
                                            <div className="col-12 pb-2">
                                                <div className="fs-2 text-success fw-bold">Электронные путевые для такси </div>
                                            </div>
                                            <div className="col-12 pb-2">
                                                <div className="fs-2 text-danger fw-bold">Три месяц бесплатно по промокоду</div>
                                            </div>
                                            <div className="col-md-12 d-flex justify-content-center align-items-center text-center ">
                                                <button onClick={handleRedirect} className="d-flex fw-bold w-70 rounded-4 fs-4 p-2 pb-2 pt-2 custom-shadow" style={{backgroundColor: '#bcfc2c'} }>
                                                    <div className="d-flex justify-content-center lign-items-center text-center">
                                                        СКАЧАТЬ ПРИЛОЖЕНИЕ
                                                    </div>
                                                    <img src="/images/icon_button.png" width="35" height="35" alt=""/>
                                                </button>
                                            </div>
                                            <div className="col-12">
                                                {/*<img src="images/button.jpg" width="300" height="100"*/}
                                                {/*     className="mx-auto d-block"/>*/}
                                                <div className="fs-1 text-success fw-bold">АКЦИЯ</div>
                                                <div>
                                                    <h5 className="text-success fw-bold d-inline-block">ТОЛЬКО ДО</h5> <h5
                                                    className="text-danger fw-bold d-inline-block">31 ДЕКАБРЯ</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-none d-md-block col-md-2 p-0">
                                        <img src="images/new_year_toy.png" className="img-fluid" alt=""/>
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 card rounded-0 shadow-lg">
                                <div className="card-title border-bottom border-success border-2">
                                    <h3 className="fw-bold">Электронные путевые Беслпатно {region.namecase.prepositional}</h3>
                                </div>
                                <div className="card-body">
                                    Расстояние от Домодедово до Шерметьево - почти 70 км по прямой. Чтобы попасть из одного аэропорта в
                                    другой, нужно проехать всю Москву с юго-востока на северо-запад. Путь не самый простой: придется добираться
                                    с пересадками или постоять в пробках. Но деваться некуда-часто случается так, что требуется сделать пересадку
                                    и ехать из одного аэропорта в другой.<br className="mb-3"/>

                                    Разберу пять вариантов, как можно быстро проехать от Домодедово до Шерметьево, а также расскажу про плюсы и минусы каждого.
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-3 col-sm-12 ps-md-4 p-0">
                        <div className="card rounded-0 shadow-lg h-100 p-2">
                            <div className="card-title border-bottom border-success border-2">
                                <h5 className="fw-bold">Часто задаваемые вопросы.</h5>
                            </div>
                            <div className="card-body small">
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        Как найти водителя в аэропорту?
                                    </div>
                                    <div className="col-12 mb-3">
                                        Сколько чемоданов включено в стоимость?
                                    </div>
                                    <div className="col-12 mb-3">
                                        Заказать такси лучше по прилету или заранее?
                                    </div>
                                    <div className="col-12 mb-3">
                                        Как узнать сколько по времени мы будем ехать?
                                    </div>
                                    <div className="col-12 mb-3">
                                        Когда назначится на заказ водитель?
                                    </div>
                                    <div className="col-12 mb-3">
                                        Как установить приложение для заказа такси в телефон?
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-12 col-sm-12 card rounded-0 mb-4 shadow-lg">
                        <div className="card-title ">
                            <h4 className="border-bottom border-success border-2 fw-bold">Карта сайта</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {regions.map((item, index) => (
                                    <div className="col-3">
                                        <Link to={`/${urls.find((r) => r.guid === item.guid).url}`}>{item.namecase.nominative}</Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 card rounded-0 shadow-lg ">
                        <div className="card-title">
                            <h4 className="border-bottom border-success border-2 fw-bold">Контакты</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-4">
                                    Тел. +7(925)500-13-13
                                </div>
                                <div className="col-4">
                                    Адрес г. Москва ул. Вернадского 45 офис 15
                                </div>
                                <div className="col-4">
                                    Заказть звонок
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Layout>
        </>
    );
};

export default RegionPage;