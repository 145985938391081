import React from 'react';
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';

const HeaderComponent = () => {

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Popover Header</Popover.Header>
            <Popover.Body>
                This is some content inside the popover. You can place anything here!
            </Popover.Body>
        </Popover>
    );

    const prompt = (
        <Popover id="popover-basic1">
            <Popover.Body>
                <p>Для того чтобы загрузить фотографию вам
                    нужно <a href="https://xn----7sbbbdyunf1ad1d.xn--p1ai/register"
                             className="link-green">Зарегистрироваться</a></p>
            </Popover.Body>
        </Popover>
    );

    const prompt1 = (
        <Popover id="popover-basic2">
            <Popover.Body>
                <a href="#">Выключить подсказки</a> <a
                href="https://xn----7sbbbdyunf1ad1d.xn--p1ai/register">Регистрация</a>
                <a href="https://xn----7sbbbdyunf1ad1d.xn--p1ai/login">Вход</a> <a
                href="#">Сохранить сайт</a>
            </Popover.Body>
        </Popover>
    );

    return (
        <header className="header-main mb-4">
            <div className='container-fluid'>
                <div className='row'>
                    <div className="col-9 col-md-11">
                        <div className="logo">
                            <a href="">
                                <img src="/images/logo_new.png" title="ЛОБА-Сетка"/>
                            </a>
                        </div>
                        <nav className="main-menu d-none d-md-inline-block">
                            <ul>
                                <a href="http://лоба-почта.рф" title="Электронная почта">
                                    <li>
                                        <i className="icon icon-mail"></i>
                                    </li>
                                </a>
                                <a href="http://лоба-доска.рф" title="Доска бесплатных объявлений">
                                    <li>
                                        <i className="icon icon-otcher"></i>
                                    </li>
                                </a>
                                <a href="http://лоба-такси.рф" title="Заказ такси">
                                    <li>
                                        <i className="icon icon-taxi"></i>
                                    </li>
                                </a>
                                <a href="http://лоба-партнер.рф" title="Партнерки">
                                    <li>
                                        <i className="icon icon-handshake"></i>
                                    </li>
                                </a>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-3 col-md-1 text-right">
                        <nav className="nav-user">
                            <ul>
                                <OverlayTrigger trigger="click" placement="bottom" overlay={prompt}>
                                    <li id="avtar-user-nav" className="">
                                        <i className="icon icon-user"></i>
                                    </li>
                                </OverlayTrigger>
                                <OverlayTrigger trigger="click" placement="bottom" overlay={prompt1}>
                                    <li>
                                        <i className="icon icon-settings"></i>
                                    </li>
                                </OverlayTrigger>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="App">
                {/* Используем OverlayTrigger для отображения Popover */}

            </div>
        </header>
    );
};

export default HeaderComponent;